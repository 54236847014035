
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as abuseReports3bGhKtKPIoMeta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/admin/abuseReports.vue?macro=true";
import { default as _91id_93PGnO9hK2aZMeta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/admin/audit-log/[username]/[id].vue?macro=true";
import { default as editoru3uX3XL4xGMeta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/admin/blog/editor.vue?macro=true";
import { default as indexEzd4aiDQR5Meta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/admin/index.vue?macro=true";
import { default as moderationCMTdqLkHT1Meta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/admin/moderation.vue?macro=true";
import { default as pendingBansIHCSQVeVc1Meta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/admin/pendingBans.vue?macro=true";
import { default as profilesAIw3gfOT8dMeta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/admin/profiles.vue?macro=true";
import { default as expensesmK9bqQE0ugMeta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/admin/timesheets/expenses.vue?macro=true";
import { default as indexfgvunUSAD6Meta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/admin/timesheets/index.vue?macro=true";
import { default as overviewVy04eQtAeqMeta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/admin/timesheets/overview.vue?macro=true";
import { default as awaitingcu9ixVs5r7Meta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/admin/translations/awaiting.vue?macro=true";
import { default as missingHaixZuqycSMeta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/admin/translations/missing.vue?macro=true";
import { default as usersCblw8T8LETMeta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/admin/users.vue?macro=true";
import { default as api2PJDvqVPiiMeta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/api.vue?macro=true";
import { default as _91slug_93bemMnyGOTzMeta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/blog/[slug].vue?macro=true";
import { default as indexEoYmF31GhEMeta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/blog/index.vue?macro=true";
import { default as _91_91year_93_93sQzNLkQZkrMeta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/calendar/[[year]].vue?macro=true";
import { default as _91year_93_45_91month_93_45_91day_93mAjpoqPwnAMeta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/calendar/[year]-[month]-[day].vue?macro=true";
import { default as adminiChqgjlrMFMeta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/census/admin.vue?macro=true";
import { default as indexLtmKPGHZ4JMeta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/census/index.vue?macro=true";
import { default as contactoVGK2jYw81Meta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/contact.vue?macro=true";
import { default as designrt2cskCPjHMeta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/design.vue?macro=true";
import { default as englishM3iQ4Xg8xkMeta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/english.vue?macro=true";
import { default as faq1lti5c53tHMeta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/faq.vue?macro=true";
import { default as inclusivei2H4pLN8pSMeta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/inclusive.vue?macro=true";
import { default as index_45home25wIIQfFrQMeta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/index-home.vue?macro=true";
import { default as index_45localeGGLEm0ugNJMeta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/index-locale.vue?macro=true";
import { default as langswitchuZF2m5m4HXMeta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/langswitch.vue?macro=true";
import { default as licenset5lBLiqdTqMeta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/license.vue?macro=true";
import { default as academicII3Bo0CeQyMeta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/links/academic.vue?macro=true";
import { default as indexWKJAtV9nhBMeta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/links/index.vue?macro=true";
import { default as mediag1Di1EOBQsMeta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/links/media.vue?macro=true";
import { default as translinguistics0YouWRSTmKMeta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/links/translinguistics.vue?macro=true";
import { default as zine0El27AhAtrMeta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/links/zine.vue?macro=true";
import { default as nameswwPNyFOukEMeta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/names.vue?macro=true";
import { default as indexEunIYLkctPMeta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/nouns/index.vue?macro=true";
import { default as templatesvePRQnAfoJMeta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/nouns/templates.vue?macro=true";
import { default as peopleJCrQKrHyIEMeta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/people.vue?macro=true";
import { default as privacyenLytPDXgLMeta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/privacy.vue?macro=true";
import { default as _91username_93GANXPghXWVMeta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/profile/[username].vue?macro=true";
import { default as card_45_91username_93FxgNliyEDgMeta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/profile/card-[username].vue?macro=true";
import { default as editormZn0aNTDlmMeta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/profile/editor.vue?macro=true";
import { default as anyv1sou2xRGAMeta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/pronouns/any.vue?macro=true";
import { default as askcMJCm9vg3UMeta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/pronouns/ask.vue?macro=true";
import { default as avoidingRZL9FhKyGAMeta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/pronouns/avoiding.vue?macro=true";
import { default as index9uQJuoZpEdMeta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/pronouns/index.vue?macro=true";
import { default as mirrorjq9H07cQZ8Meta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/pronouns/mirror.vue?macro=true";
import { default as pronounLeuteTiEfbMeta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/pronouns/pronoun.vue?macro=true";
import { default as sourcesROf4fRbHiYMeta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/sources.vue?macro=true";
import { default as team2PMFXxHtw2Meta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/team.vue?macro=true";
import { default as terminologyx54ac6qWsIMeta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/terminology.vue?macro=true";
import { default as termsjeIoHUQxTEMeta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/terms.vue?macro=true";
import { default as userIsm2LYoQC2Meta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/user.vue?macro=true";
import { default as workshopsueg2iXIPwJMeta } from "/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/workshops.vue?macro=true";
export default [
  {
    name: "admin-abuseReports",
    path: "/admin/abuse-reports",
    meta: abuseReports3bGhKtKPIoMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/admin/abuseReports.vue")
  },
  {
    name: "admin-audit-log-username-id",
    path: "/admin/audit-log/:username()/:id()",
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/admin/audit-log/[username]/[id].vue")
  },
  {
    name: "admin-blog-editor",
    path: "/admin/blog/editor",
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/admin/blog/editor.vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/admin/index.vue")
  },
  {
    name: "admin-moderation",
    path: "/admin/moderation",
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/admin/moderation.vue")
  },
  {
    name: "admin-pendingBans",
    path: "/admin/pending-bans",
    meta: pendingBansIHCSQVeVc1Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/admin/pendingBans.vue")
  },
  {
    name: "admin-profiles",
    path: "/admin/profiles",
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/admin/profiles.vue")
  },
  {
    name: "admin-timesheets-expenses",
    path: "/admin/timesheets/expenses",
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/admin/timesheets/expenses.vue")
  },
  {
    name: "admin-timesheets",
    path: "/admin/timesheets",
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/admin/timesheets/index.vue")
  },
  {
    name: "admin-timesheets-overview",
    path: "/admin/timesheets/overview",
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/admin/timesheets/overview.vue")
  },
  {
    name: "admin-translations-awaiting",
    path: "/admin/translations/awaiting",
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/admin/translations/awaiting.vue")
  },
  {
    name: "admin-translations-missing",
    path: "/admin/translations/missing",
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/admin/translations/missing.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/admin/users.vue")
  },
  {
    name: "api",
    path: "/api",
    meta: api2PJDvqVPiiMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/api.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93bemMnyGOTzMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexEoYmF31GhEMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/blog/index.vue")
  },
  {
    name: "calendar",
    path: "/calendar/:year?",
    meta: _91_91year_93_93sQzNLkQZkrMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/calendar/[[year]].vue")
  },
  {
    name: "calendarDay",
    path: "/calendar/:year()-:month()-:day()",
    meta: _91year_93_45_91month_93_45_91day_93mAjpoqPwnAMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/calendar/[year]-[month]-[day].vue")
  },
  {
    name: "census-admin",
    path: "/census/admin",
    meta: adminiChqgjlrMFMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/census/admin.vue")
  },
  {
    name: "census",
    path: "/census",
    meta: indexLtmKPGHZ4JMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/census/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactoVGK2jYw81Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/contact.vue")
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/design.vue")
  },
  {
    name: "english",
    path: "/english",
    meta: englishM3iQ4Xg8xkMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/english.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faq1lti5c53tHMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/faq.vue")
  },
  {
    name: "inclusive",
    path: "/inclusive",
    meta: inclusivei2H4pLN8pSMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/inclusive.vue")
  },
  {
    name: "index-home",
    path: "/index-home",
    meta: index_45home25wIIQfFrQMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/index-home.vue")
  },
  {
    name: "index",
    path: "/index-locale",
    meta: index_45localeGGLEm0ugNJMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/index-locale.vue")
  },
  {
    name: "langswitch",
    path: "/langswitch",
    meta: langswitchuZF2m5m4HXMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/langswitch.vue")
  },
  {
    name: "license",
    path: "/license",
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/license.vue")
  },
  {
    name: "links-academic",
    path: "/links/academic",
    meta: academicII3Bo0CeQyMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/links/academic.vue")
  },
  {
    name: "links",
    path: "/links",
    meta: indexWKJAtV9nhBMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/links/index.vue")
  },
  {
    name: "links-media",
    path: "/links/media",
    meta: mediag1Di1EOBQsMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/links/media.vue")
  },
  {
    name: "links-translinguistics",
    path: "/links/translinguistics",
    meta: translinguistics0YouWRSTmKMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/links/translinguistics.vue")
  },
  {
    name: "links-zine",
    path: "/links/zine",
    meta: zine0El27AhAtrMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/links/zine.vue")
  },
  {
    name: "names",
    path: "/names",
    meta: nameswwPNyFOukEMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/names.vue")
  },
  {
    name: "nouns",
    path: "/nouns",
    meta: indexEunIYLkctPMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/nouns/index.vue")
  },
  {
    name: "nouns-templates",
    path: "/nouns/templates",
    meta: templatesvePRQnAfoJMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/nouns/templates.vue")
  },
  {
    name: "people",
    path: "/people",
    meta: peopleJCrQKrHyIEMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/people.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyenLytPDXgLMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/privacy.vue")
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: _91username_93GANXPghXWVMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/profile/[username].vue")
  },
  {
    name: "profile-card-username",
    path: "/profile/card-:username()",
    meta: card_45_91username_93FxgNliyEDgMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/profile/card-[username].vue")
  },
  {
    name: "profile-editor",
    path: "/profile/editor",
    meta: editormZn0aNTDlmMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/profile/editor.vue")
  },
  {
    name: "pronouns-any",
    path: "/pronouns/any",
    meta: anyv1sou2xRGAMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/pronouns/any.vue")
  },
  {
    name: "pronouns-ask",
    path: "/pronouns/ask",
    meta: askcMJCm9vg3UMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/pronouns/ask.vue")
  },
  {
    name: "pronouns-avoiding",
    path: "/pronouns/avoiding",
    meta: avoidingRZL9FhKyGAMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/pronouns/avoiding.vue")
  },
  {
    name: "pronouns",
    path: "/pronouns",
    meta: index9uQJuoZpEdMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/pronouns/index.vue")
  },
  {
    name: "pronouns-mirror",
    path: "/pronouns/mirror",
    meta: mirrorjq9H07cQZ8Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/pronouns/mirror.vue")
  },
  {
    name: "all",
    path: "/:path(.*)",
    meta: pronounLeuteTiEfbMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/pronouns/pronoun.vue")
  },
  {
    name: "sources",
    path: "/sources",
    meta: sourcesROf4fRbHiYMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/sources.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: team2PMFXxHtw2Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/team.vue")
  },
  {
    name: "terminology",
    path: "/terminology",
    meta: terminologyx54ac6qWsIMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/terminology.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsjeIoHUQxTEMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/terms.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: userIsm2LYoQC2Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/user.vue")
  },
  {
    name: "workshops",
    path: "/workshops",
    meta: workshopsueg2iXIPwJMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/2025-01-28--20-20-27/pages/workshops.vue")
  }
]